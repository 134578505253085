import { Route } from 'react-router-dom';
import NotificationBar from '../../CommonComponents/NotificationBar/NotificationBar';
import PublicNav from '../../CommonComponents/PublicNav/PublicNav';

const PublicRoute = ({component: Component, ...rest}) => <Route {...rest} render={() => <>
  <PublicNav/>
  <NotificationBar />
  <Component />
</>} />;

export default PublicRoute;