import React, { useRef, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import OroSafeLogo from '../../assets/OroSafeLogo.svg';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import styles from './PublicNav.module.scss';

const Navlinks = [
  { text: 'Oro Money', to: 'https://orocorp.in', isExternal: true },
  { text: 'Oro Safe', to: process.env.REACT_APP_LANDING_URL, isExternal: true },
  { text: 'About Us', to: 'https://orocorp.in', isExternal: true },
  { text: 'Careers', to: 'https://orocorp.in', isExternal: true },
  { text: 'Contact Us', to: 'https://orocorp.in/contact-us', isExternal: true}
];

function PublicNav({ location }) {
  const [isMenu, setMenu] = useState(false);
  const sidebar = useRef();

  function toggle() {
    if(!sidebar.current) {
      setMenu(true);
      return;
    }
    if(!isMenu) {
      sidebar.current.parentNode.classList.remove('animate-fadeOut');
      sidebar.current.parentNode.classList.add('animate-fade');
      sidebar.current.classList.remove('animate-sidebar-close');
      sidebar.current.classList.add('animate-notification-slide');
    } else {
      sidebar.current.parentNode.classList.remove('animate-fade');
      sidebar.current.parentNode.classList.add('animate-fadeOut');
      sidebar.current.classList.remove('animate-notification-slide');
      sidebar.current.classList.add('animate-sidebar-close');
    }
    setTimeout(() => setMenu(!isMenu), 200);
  }

  return <div className="flex z-10 w-full relative justify-between bg-white pr-5" >
    <a href={process.env.REACT_APP_LANDING_URL} className="cursor-pointer" rel="noopener noreferrer">
      <img width={133} height={100} className="w-[93px]" src={OroSafeLogo} alt="Oro Safe Logo" />
    </a>
    <div className="hidden md:flex w-full justify-center items-center">
      {Navlinks.map(({ text, to, isExternal }) => isExternal? 
        <a key={to} className={`${location.pathname === to && 'border-[#F9CA36] border-b-4'} text-xs lg:text-base font-bold mx-10`} 
          href={to} rel="noreferrer noopener">
          {text}
        </a> : 
        <Link key={to} className={`${location.pathname === to && 'border-[#F9CA36] border-b-4'} text-xs lg:text-base font-bold mx-10`} 
          to={to}>
          {text}
        </Link> 
      )}
    </div>
    {!isMenu && <div className="md:hidden flex items-center">
      <MenuOutlined className="text-base sm:text-xl md:text-2xl" onClick={toggle} />
    </div>}
    {isMenu && <div className={`animate-fade flex flex-col items-end md:hidden fixed top-0 left-0 w-screen h-screen 
    ${styles.mobileSideBarBackground} z-10`} onClick={toggle}>
      <div ref={sidebar} className="animate-notification-slide bg-white flex flex-col items-center h-full" onClick={(e) => e.stopPropagation()}>
        <CloseOutlined className="self-end text-2xl mr-5 my-5" onClick={toggle} />
        {Navlinks.map(({ text, to, isExternal}) => isExternal ?
          <a className={`${location.pathname === to && 'border-[#F9CA36] border-b-4'} self-start text-base font-bold ml-5 mr-28 my-5`} 
            href={to} rel="noreferrer noopener">
            {text}
          </a> :
          <Link className={`${location.pathname === to && 'border-[#F9CA36] border-b-4'} self-start text-base font-bold ml-5 mr-28 my-5`} to={to}
            onClick={toggle}>
            {text}
          </Link>
        )}
      </div>
    </div>}
  </div>;
};

export default withRouter(PublicNav);
