export const SET_ACCOUNT_DETAILS = 'SET_ACCOUNT_DETAILS';
export const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION';
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';

export const setAccountDetails = (details) => {
  return {
    type: SET_ACCOUNT_DETAILS,
    details
  };
}

export const pushNotification = (notification) => {
  return {
    type: PUSH_NOTIFICATION,
    notification
  };
};

export const closeNotification = (key) => {
  return {
    type: CLOSE_NOTIFICATION,
    key
  };
};
