import * as Locker from 'lockr';

const AuthUtils = {
  getToken() {
    return Locker.get('token');
  },
  getRefreshToken() {
    return Locker.get('refreshToken');
  },
  setToken (token) {
    Locker.set('token', token);
  },
  login(data) {
    Locker.set('token', data?.token);
    Locker.set('refreshToken', data?.refreshToken);
  },
  logout() {
    Locker.rm('token');
    Locker.rm('refreshToken');
    window.location.reload();
  },
  isLoggedIn() {
    if(Locker.get('token') && Locker.get('refreshToken')) return true;
    return false;
  }
};

export default AuthUtils;
