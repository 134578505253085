import { closeNotification, CLOSE_NOTIFICATION, PUSH_NOTIFICATION, SET_ACCOUNT_DETAILS } from './actions';
import { combineReducers, createStore } from 'redux';

const accountDetails = (state = '', action) => {
  switch(action.type){
  case SET_ACCOUNT_DETAILS:
    return action.details;
  default:
    return state;
  }
};

const notifications = (state = [], action) => {
  switch(action.type) {
  case PUSH_NOTIFICATION:
    setTimeout(() => store.dispatch(closeNotification(action?.notification?.key)), 4000);
    return [action.notification, ...state];
  case CLOSE_NOTIFICATION:
    return [...state].filter(({key}) => key !== action.key);
  default:
    return state;
  }
}

const rootReducer = combineReducers({
  accountDetails,
  notifications
});
const store = createStore(
  rootReducer,
  process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
