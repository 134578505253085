
import React, { useState, Suspense, lazy } from 'react';
import {
  Redirect, Route, BrowserRouter as Router, Switch,
} from 'react-router-dom';
// Components
import PublicRoute from './Components/PublicPages/PublicRoute';
const Login = lazy(() => import('./Components/PublicPages/Login/Login'));
const Landing = lazy(() => import('./Components/PublicPages/Landing/Landing'));
const PrivateRouter = lazy(() => import('./Components/PrivatePages/PrivateRouter'));
import Loader from './CommonComponents/Loader';
// utils
import AuthUtils from './utils/AuthUtils';
// styles
import './App.css';
import NotificationBar from './CommonComponents/NotificationBar/NotificationBar';
import PublicNav from './CommonComponents/PublicNav/PublicNav';

function App() {
  const [isLoggedIn, updateLoginStatus] = useState(AuthUtils.isLoggedIn());

  function logIn() {
    if(AuthUtils.isLoggedIn()) updateLoginStatus(true);
  }

  return (
    <div className="App bg-light-grey">
      <Router>
        <Suspense fallback={<>
          <PublicNav />
          <NotificationBar />
          <div className="w-full pt-28 flex justify-center bg-light-grey">
            <Loader className="text-grey-400" />
          </div>
        </>}>
          <Switch>
            {isLoggedIn && <Route path="/" component={PrivateRouter} />}
            <PublicRoute path="/" exact component={Landing} />
            <PublicRoute path="/login" exact component={() => <Login logIn={logIn} />} />
            <Route path="*" exact component={() => <Redirect to="/" />} />
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
